



















import Vue from 'vue'
import Picture from '~/components/commons/bases/picture/picture.vue'
import Heading from '~/components/commons/bases/typography/heading/heading.vue'
// import TextBody from '~/components/commons/bases/typography/text-body/text-body.vue'
import Caption from '~/components/commons/bases/typography/caption/caption.vue'

export default Vue.extend({
  name: 'ProductBox',
  components: {
    Picture,
    Heading,
    Caption
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  }
})
