






import 'intersection-observer'
import Vue from 'vue'

export default Vue.extend({
  name: 'WithAnimation',
  props: {
    /**
     * Type of animation to use
     */
    animationType: {
      type: String,
      required: true,
      validator: val => ['fade-in', 'fly-from-right'].includes(val)
    },
    /**
     * Amount to increment delay for each subsequent component
     */
    delayIncrement: {
      type: Number,
      default: 0
    },
    /**
     * Threshold for component to be in view before animation starts
     */
    threshold: {
      type: Number,
      default: 0.7
    }
  },
  mounted() {
    this.addAnimation()
  },
  updated() {
    this.addAnimation()
  },

  methods: {
    addAnimation() {
      const options = {
        threshold: this.$props.threshold
      }
      const initialRatio = 0
      let delay = 0

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > initialRatio) {
            const target = entry.target as HTMLElement
            target.classList.add(this.$props.animationType)
            target.style.animationDelay = `${delay}s`
            delay += this.$props.delayIncrement

            target.classList.remove('animated')
            observer.unobserve(target)
          }
        })
      }, options)

      const targets = (this.$refs.root as HTMLElement).querySelectorAll(
        '.animated'
      )
      targets.forEach(target => {
        observer.observe(target)
      })
    }
  }
})
