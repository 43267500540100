

























import Vue from 'vue'
import { TextBody } from '~/components/commons/bases/typography/index'
export default Vue.extend({
  name: 'Card',
  components: {
    TextBody
  },
  props: {
    background: {
      type: String,
      default: '#ffffff'
    },
    externalImageSource: {
      type: Boolean,
      required: false,
      default: false
    },
    image: {
      type: String,
      required: true,
      default: null
    },
    title: {
      type: String,
      required: true
    },
    link: {
      type: [String, Object],
      required: true,
      default: null
    },
    target: {
      type: String,
      required: false,
      default: '_blank'
    },
    goExternal: {
      type: Boolean,
      required: false,
      default: false
    },
    dataTestImage: {
      type: String,
      required: false,
      default: 'false'
    },
    altText: {
      type: String,
      required: false,
      default: 'pic'
    },
    description: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    isShowRevamp() {
      return this.$features('DWB-XXX-REVAMP-2023-Q4-F')
    }
  }
})
