<template>
  <hr :class="type" />
</template>

<script>
export default {
  name: 'DividerLine',
  props: {
    /**
     * enum ['grey-line', 'dark-line']
     */
    type: {
      required: false,
      type: String,
      default() {
        return 'grey-line'
      },
      validator: type =>
        ['grey-line', 'dark-line', 'grey-solid-line'].includes(type)
    }
  }
}
</script>

<style lang="scss" scoped>
.grey-line {
  border: none;
  display: block;
  background-color: $white-smoke;
  height: 1px;
  margin: 0;
}
.dark-line {
  background-color: #4a4a4a;
  height: 3px;
  display: block;
}

.grey-solid-line {
  height: 1px;
  border: solid 0.5px #eaeaea;
}
</style>
