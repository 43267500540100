























import Vue from 'vue'
import {
  TextBody,
  TitleSmall
} from '~/components/commons/bases/typography/index'
import Picture from '~/components/commons/bases/picture/picture.vue'
import ShellMixin from '~/mixins/shellMixin'

export default Vue.extend({
  name: 'LendingPurposeContent',
  components: {
    TextBody,
    TitleSmall,
    Picture
  },
  mixins: [ShellMixin],
  props: {
    content: {
      type: Object,
      required: true
    }
  }
})
