<template>
  <section class="section-container">
    <div class="hero-image-container">
      <nuxt-link
        :to="item.url + '?events=2'"
        :data-test="item.dataTest"
        @click.native="bannerClick"
      >
        <CarouselSectionContainer
          class="hero-image-wrapper"
          :img-mobile="item.carouselImage.mobile"
          :img-tablet="item.carouselImage.tablet"
          :img-desktop="item.carouselImage.desktop"
          :img-id="item.dataTest"
          :alt-text="item.carouselImage.alt"
          :test-id="item.dataTest"
        />
      </nuxt-link>
    </div>
  </section>
</template>
<script>
import Vue from 'vue'
import CarouselSectionContainer from '~/components/layouts/section-container/carousel-section-container.vue'
export default Vue.extend({
  components: {
    CarouselSectionContainer
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: null
    }
  },
  methods: {
    bannerClick() {
      this.$ga.event({
        eventAction: 'click_banner_from_carousel',
        eventCategory: `${this.item.seo.label}_campaign`,
        eventLabel: `${this.item.seo.label}_campaign`
      })
      this.$gtag.event('click', {
        event_action: 'click_banner_from_carousel',
        event_label: this.item.utmCampaign
      })
    }
  }
})
</script>
