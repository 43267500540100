













import Vue from 'vue'
import Contents from '../../pages/contents/footer-contents.vue'
import HighlightSection from '../pages-specific/home/highlight-section.vue'
import LoanApplyStepSection from '../pages-specific/home/loan-apply-step-section.vue'
import FaqSection from '../pages-specific/home/faq-section.vue'
import ProductSection from '../pages-specific/home/product-section.vue'
import Carousel from '../commons/organisms/carousel/carousel.vue'
import CarForCashCanHelp from '../pages-specific/home/car-for-cash-can-help.vue'

export default Vue.extend({
  components: {
    CarForCashCanHelp,
    HighlightSection,
    LoanApplyStepSection,
    FaqSection,
    ProductSection,
    Carousel,
    Contents
  }
})
