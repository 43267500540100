























import Vue from 'vue'
import { Heading } from '~/components/commons/bases/typography'
import Picture from '~/components/commons/bases/picture/picture.vue'

export default Vue.extend({
  name: 'BadgeBox',
  components: {
    Heading,
    Picture
  },
  props: {
    /**
     * This name will be icon alt tag
     */
    name: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Title of this component
     */
    title: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Icon location in project
     */
    icon: {
      type: String,
      required: true,
      default: null
    },
    /**
     * Title html render tag default h4 as `Heading`
     */
    titleTag: {
      type: String,
      required: false,
      default: undefined
    },
    height: {
      type: String,
      required: false,
      default: undefined
    },
    width: {
      type: String,
      required: false,
      default: undefined
    }
  }
})
