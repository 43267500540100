<template>
  <!-- Refference for carousel
    https://bootstrap-vue.js.org/docs/
    https://bootstrap-vue.js.org/docs/components/carousel
    I was create custom.css for modify default of bootstrap.css
    Some function in custom.css is use same default function.
    I will rename function in custom.css with -koh-backup
   -->
  <div>
    <b-carousel
      id="carousel-1"
      ref="myCarousel"
      v-model="slide"
      :interval="timeInterval"
      :no-touch="isHideOverflow"
      controls
      indicators
      img-width="1024"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide v-if="isCustomerFloodOntime()">
        <section class="section-container">
          <div class="hero-image-container">
            <a
              href="https://kautolink.com/fAch34"
              target="_blank"
              data-test="customer-flood"
              @click.native="bannerClick"
            >
              <CarouselSectionContainer
                class="hero-image-wrapper"
                img-mobile="images/customer-flood-mobile.jpg"
                img-tablet="images/customer-flood-tablet.jpg"
                img-desktop="images/customer-flood-desktop.jpg"
                img-id="customer-flood"
                alt-text="มาตรการช่วยเหลือลูกค้าที่ได้รับผลกระทบจากน้ำท่วม"
                test-id="customer-flood"
              />
            </a>
          </div>
        </section>
      </b-carousel-slide>
      <b-carousel-slide
        v-for="(item, index) in promotions"
        :key="`item-${index}`"
      >
        <Template :id="item.dataTest" :item="item" />
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import Vue from 'vue'
import BootstrapVuePlugin from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'assets/stylesheets/custom.css'
import { filter, get, isEmpty } from 'lodash'
import { isWithinInterval, isAfter, isBefore } from 'date-fns'
import Template from './template'
import Promotions from '~/pages/promotions/configs-promotions'
import CarouselSectionContainer from '~/components/layouts/section-container/carousel-section-container.vue'

Vue.use(BootstrapVuePlugin)

export default {
  components: {
    Template,
    CarouselSectionContainer
  },
  data() {
    const promotions = filter(Promotions, promotion => this.onTime(promotion))
    return {
      promotions,
      slide: 0,
      sliding: null,
      testKey: 0,
      timeInterval: 5000,
      isHideOverflow: false
    }
  },
  methods: {
    bannerClick() {
      this.$ga.event({
        eventAction: 'click_banner_from_carousel',
        eventCategory: 'customer_flood_campaign',
        eventLabel: 'customer_flood_campaign'
      })
    },
    isCustomerFloodOntime() {
      const publishDown = '2022/11/30 23:59:59 GMT+07:00'
      const isOnTime = isBefore(new Date(), new Date(publishDown))

      const haveFeatureToggle = this.$features('DWB-428-CUSTOMER-FLOOD-F')

      return isOnTime && haveFeatureToggle
    },
    onTime(promotion) {
      let isOnTime = true
      let publishUp = promotion.publishUp
      const publishUpProd = get(promotion, 'publishUpProd', null)

      if (this.$env.ENVIRONMENT_NAME === 'prod' && publishUpProd) {
        publishUp = publishUpProd
      }
      const start = `${publishUp} 00:00:00 GMT+07:00`.replace(/-/g, '/')

      if (isEmpty(promotion.publishDown)) {
        isOnTime = isAfter(new Date(), new Date(start))
      } else {
        const down = `${promotion.publishDown} 23:59:59 GMT+07:00`.replace(
          /-/g,
          '/'
        )
        isOnTime = isWithinInterval(
          new Date(), // Now
          {
            start: new Date(start),
            end: new Date(down)
          }
        )
      }
      const haveFeatureToggle =
        typeof promotion.featureToggle === 'string'
          ? this.$features(promotion.featureToggle)
          : promotion.featureToggle

      return haveFeatureToggle && isOnTime
    },
    modalOpened() {
      this.timeInterval = 0
      this.isHideOverflow = true
    },
    modalClosed() {
      this.timeInterval = 5000
      this.isHideOverflow = false
    },
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    }
  }
}
</script>
<style lang="scss">
.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 2px;
  margin-left: 2px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 0px solid transparent;
  border-bottom: 0px solid transparent;
  opacity: 1;
  transition: opacity 0.6s ease;
  bottom: -50px;

  @include desktop {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 2px;
    margin-left: 2px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    opacity: 1;
    transition: opacity 0.6s ease;
  }
  @include tablet {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 2px;
    margin-left: 2px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    opacity: 1;
    transition: opacity 0.6s ease;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
  @include mobile {
    display: none;
  }
  @include tablet {
    width: 1%;
  }
  @include desktop {
    width: 5%;
  }
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: -40px;
  @include mobile {
    // bottom: 60px;
  }
  @include tablet {
    bottom: -40px;
  }
  @include desktop {
    bottom: -40px;
  }

  left: 0;
  //  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators > li {
  background-color: #a89d9d;
  outline: none;
}
.carousel-control-next-icon {
  color: $yellow;
}
</style>
